import request from '@/utils/request'

export function getDisagreementReasonById(data) {
  return request({
    url: `/api/DisagreementReason/GetDisagreementReasonById/${data}`,
    method: 'get',
  })
}
export function getDisagreementReasons() {
  return request({
    url: '/api/DisagreementReason/GetDisagreementReasons',
    method: 'get',
  })
}

export function addDisagreementReason(data) {
  return request({
    url: '/api/DisagreementReason/AddDisagreementReason',
    method: 'post',
    data,
  })
}

export function updateDisagreementReason(data) {
  return request({
    url: '/api/DisagreementReason/UpdateDisagreementReason',
    method: 'put',
    data,
  })
}
export function deleteDisagreementReason(data) {
  return request({
    url: `/api/DisagreementReason/DeleteDisagreementReason?disagreementReasonId=${data}`,
    method: 'delete',
  })
}
